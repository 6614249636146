body {
	margin: 0;

	color: white;
	text-decoration: none;
	font-size: 2em;

	background-color: black;
}

a {
	text-decoration: none;
	color: white;
}

#root {
	height: 100%;
	width: 100%;
}

:focus {
	outline: none;
}

* {
	font-family: trajan Arial;
}

@font-face {
	font-family: trajan;
	src: url(/public/Trajan Pro Regular.ttf);
}

/********/
/* MENU */
/********/
.menu {
	position: fixed;
	width: 100%;
	background-color: black;
	color: white;

	z-index: 10;
}

.menu.active {
	z-index: 15;
}

.top-bar {
	height: 3em;
}

.top-bar .logo {
	float:left;
}

.cellyon-logo {
	height: 80%;
	margin: 0.3em 0.5em;
}

.menu-button {
	float: right;
	margin: 1em;
	padding: 5px 0px;
	cursor: pointer;
}

.menu-button img {
	display: block;
	margin: 4px auto;
	transition: transform 500ms ease-in-out, margin 450ms ease-in-out 50ms;
}

.menu.active .menu-button img.top,
.menu-button.active img.top {
	transform: rotate(45deg);
	margin-top: 5px;
}

.menu.active .menu-button img.bottom,
.menu-button.active img.bottom {
	transform: rotate(-45deg);
	margin-top: -11px;
}

.menu-content {
	background-image: url(/images/menu_background.png);
	background-size: cover;
	transition: height 500ms ease-in-out;
	overflow: hidden;

	height: 0vh;
	width: 100%;
}

.menu-content ul {
	display: flex;
	flex-direction: column;
}

.menu-content li {
	text-transform: uppercase;
	list-style: none;
	padding: 1em;
	cursor: pointer;
}

.menu-content li.indent1 {
	padding-left: 10em;
}
.menu-content li.indent2 {
	padding-left: 12em;
}
.menu-content li.indent3 {
	padding-left: 13em;
}
.menu-content li.indent4 {
	padding-left: 13.5em;
}

.menu.active .menu-content {
	height: 100vh;
}

.menu .social-media {
	position: fixed;
	right: 2em;
	bottom: 2em;

	margin: 1em;
}

.social-media a {
	background-color: black;
	border-radius: 1.5em;

	display: block;
	height: 3em;
	width: 3em;

	margin: 0.5em 0;
}

.social-media .link-image {
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;

	width: 100%;
	height: inherit;
	position: absolute;
	overflow: hidden;
}

.social-media a.discord .link-image {
	background-image: url(/images/discord_logo.png);
}

.social-media a.steam .link-image {
	background-image: url(/images/steam_logo.png);
}

.social-media a:hover {
	background-color: #1BB194;
}

.menu .social-media a p {
	width: 0;
	height: 1.2em;
	overflow: hidden;

	position: absolute;
	right: 100%;
}

.menu .social-media a:hover p {
	animation: right-to-left 500ms;
	background: linear-gradient(to right, transparent 0%, #1BB194 1em, #1BB194 100%);

	width: max-content;
	padding: 0 1em 0;
	margin-right: -0.5em;
}

@keyframes right-to-left {
	from {
		clip-path: inset(0 0 0 100%);
	}
	to {
		clip-path: inset(0 0 0 0);
	}
}

/*********/
/* PAGES */
/*********/
.page {
	background-color: black;
	background-image: url(/images/background.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	text-align: center;
	z-index: 0;
	
	width: 100%;
	height: calc(100% - 3em);

	position: relative;
	top: 3em;
}

.spacer-right {
	text-align: end;
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.spacer-left {
	text-align: start;
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.illustration {
	height: 80%;

	position: absolute;
	bottom: 0;
	z-index: -1;
}

.spacer-right .illustration {
	left: 10%;
}

.spacer-left .illustration {
	right: 10%;
}

.spacer-left h2,
.carousel-slide h2,
.page.hunt h2,
.page.bossmaker h2,
.page.comingsoon h2 {
	background: linear-gradient(to left, transparent 0%, black 3em, black 100%);
	padding: 0.5em 3em 0.5em 0.5em;

	text-transform: uppercase;
}

.spacer-right h2 {
	background: linear-gradient(to right, transparent 0%, black 3em, black 100%);
	padding: 0.5em 0.5em 0.5em 3em;

	text-transform: uppercase;
}

.page .fade-index {
	font-size: 12em;
	position: absolute;
	color: rgba(0, 0, 0, 0.5);
	font-weight: bold
}

.spacer-left .fade-index {
	right: 0.5em;
}

.spacer-right .fade-index {
	left: 0.5em;
}

.page p {
	width: 50%;
	padding: 1em;
}


.popup-background {
	background-color: rgba(0, 0, 0, 0.6);
	width: 100%;
	height: 100%;
}

.popup{
	width: 50%;
	position: relative;
	top: 10%;
	left: 25%;
}

.video-container {
	width: 100%;
	padding-top: 56.25%;
	position: relative;
}

.embeded-video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.spacer-right {
	padding-right: 20%;
}

.spacer-left {
	padding-left: 20%;
}

button.activator {
	border: 5px solid black;
	border-radius: 0;
	background-color: black;
	color: white;
	font-size: 1em;
	padding: 0.5em 1em;
}

button.activator:hover {
	border: 5px solid white;
	background-color: #1BB194;
}

.carousel-dots {
	list-style-type: none;
	margin: auto;
	text-align: center;
}

.carousel-dots li {
	display: inline-block;
}

.carousel-dots li button {
	background-color: white;
	border: 3px solid transparent;
	margin: 2em;
	color: transparent;
	border-radius: 50%;
	height: 2em;
	width: 2em;
	cursor: pointer;
}

.carousel-dots  li.slick-active button {
	background-color: #1BB194;
	border-color: #1BB194;
}

.carousel-slide {
	text-align: left;
}

.carousel-slide h2 {
	display: inline-block;
}

.carousel-slide p {
	width: fit-content;
    padding: 0 3em 0 1em;
}

.carousel-slide .illustration-wrapper img {
	height: 100%;
	margin: auto;
}

.carousel-slide .illustration-wrapper {
	margin: auto;
    height: 40vh;
    width: 80vw;
}

.slick-next {
    right: 0px;
}

.slick-prev {
    left: 0px;
}

.slick-next,
.slick-prev {
    font-size: inherit;
    z-index: 10;
}

.slick-next:before,
.slick-prev:before {
    font-size: 2em;
}

/* COVER */
.page.cover {
	position: absolute;
	z-index: 20;

	height: 100%;
	top: 0;
}

.page.cover.video {
	z-index: -1;
	object-fit: cover;
	width: 100%;
	height: 100%;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.cover-content .cellyon-title {
	height: 75vh;
}

.cover-content .cover-button {
	background-color: black;
	display: block;
	min-width: 20%;
	width: fit-content;
	padding: 0.3em 0.5em;
	margin: auto;

	text-transform: uppercase;
	font-weight: bold;
}

.cover .language-switcher {
	float: right;
	margin: 1em;
}

.language-button {
	border: none;
	font-size: inherit;
	color: inherit;
	background: none;
	outline: none;
}

.language-button.active {
	cursor: pointer;
}


/* ABOUT */

.page.about .play-trailer {
	margin: auto 20%;
	text-transform: uppercase;
	cursor: pointer;
}

.page.about .play-trailer img {
	height: 2em;
	margin: 0 0.5em;
	vertical-align: middle;
}
/* CLASSES */
.page.classes {
	padding: 0 3em;
	margin: 0;
	box-sizing: border-box;
}

/* HUNT */
.page.hunt.default {
	text-align: left;
	padding: 0 3em;
	margin: 0;
	box-sizing: border-box;
}

.page.hunt.default h2 {
	margin: 0;
	margin-top: 0.83em;
	display: inline-block;
}

.page.hunt.default p {
	margin: 0;
	width: inherit;
}

.page.hunt .back-button {
	position: absolute;
	top: 10%;
	right: 5%;
	cursor: pointer;
	font-size: 0.6em;
}

.page.hunt .boss-list {
	display: flex;
}

.page.hunt .boss-list .huntboss1 {
	background: url(/images/hunt1.png);
	background-size: cover;
}
.page.hunt .boss-list .huntboss1:hover {
	background: url(/images/huntactive1.png);
	background-size: cover;
}
.page.hunt .boss-list .huntboss2 {
	background: url(/images/hunt2.png);
	background-size: cover;
}
.page.hunt .boss-list .huntboss2:hover {
	background: url(/images/huntactive2.png);
	background-size: cover;
}
.page.hunt .boss-list .huntboss3 {
	background: url(/images/hunt3.png);
	background-size: cover;
}
.page.hunt .boss-list .huntboss3:hover {
	background: url(/images/huntactive3.png);
	background-size: cover;
}
.page.hunt .boss-list > * {
	width: 25vw;
	height: 60vh;
	margin: auto;
	cursor: pointer;
}

/* BOSS MAKER */
.page.bossmaker {
	padding: 0 3em;
	margin: 0;
	box-sizing: border-box;
	text-align: left;
}

.page.bossmaker .slick-next,
.page.bossmaker .slick-prev {
	top: 30%;
}

.page.bossmaker .carousel-slide {
	text-align: center;
	max-height: 60vh;
	position: relative;
}

.page.bossmaker .carousel-slide > * {
	margin: auto;
	display: inline-block;
	width: 75%;
	vertical-align: top;
	text-align: left;
}

.page.bossmaker .carousel-slide p {
	text-align: justify;
}

.page.bossmaker .spacer-left .fade-index {
	text-align: start;
}

.page.bossmaker .spacer-right .fade-index {
	text-align: end;
}

.page.bossmaker .spacer-left h2 {
	background: linear-gradient(to right, transparent 0%, black 3em, black 100%);
	padding: 0.5em 3em 0.5em 0.5em;
}

.page.bossmaker .spacer-right h2 {
	background: linear-gradient(to left, transparent 0%, black 3em, black 100%);
	padding: 0.5em 3em 0.5em 0.5em;
}

/* COMING SOON */
.page.comingsoon {
	padding: 0 3em;
	margin: 0;
	box-sizing: border-box;
	text-align: left;
}

.page.comingsoon h2 {
	display: inline-block;
}

.page.comingsoon .slick-next,
.page.comingsoon .slick-prev {
	top: 30%;
}

.page.comingsoon .carousel-slide {
	text-align: center;
	max-height: 60vh;
}

.page.comingsoon .carousel-slide > * {
	margin: auto;
	display: inline-block;
	width: 45%;
	vertical-align: top;
	text-align: left;
}

.page.comingsoon .carousel-slide p {
	text-align: justify;
}

/* JOIN US */
.page.joinus {
	z-index: 10;
	display: flex;
	flex-direction: column;
}

.page.joinus h3 {
	text-transform: uppercase;
}

.page.joinus .footer {
	background-color: black;
	margin: 0;
	width: auto;
}

.page.joinus .social-media {
	display: flex;
	flex: 1;
}

.page.joinus .separator {
	text-transform: uppercase;
}
.page.joinus .separator .line {
	position: relative;
	margin: 1em auto;
	width: 5px;
	background-color: black;
	flex: 1;
}

.page.joinus .separator p{
	width: inherit;
	padding: 0;
}

.page.joinus .social-media > * {
	margin: 0 auto;
	flex: 2 1;
}

.page.joinus .social-media > .separator {
	display: flex;
	flex-direction: column;
	flex: 1 1;
}

.page.joinus .social-media a {
	position: relative;
	width: 5em;
	height: 5em;
	border-radius: 2.5em;
	margin: auto;
}

.page.joinus .social-media > * > p {
	text-transform: uppercase;
	width: inherit;
	margin: auto;
}

.page.joinus .social-media .link-image {
	width: inherit;
}

.page.joinus .social-media a p {
	position: absolute;
	background-color: black;
	margin: 0;
	padding: 0.5em;
	width: 10em;
    left: -3em;
    bottom: -4em;
}

.page.joinus .social-media a:hover p {
	background-color: #1BB194;
}

.page.joinus .social-network {
	margin-bottom: 0.5em;
}

.page.joinus .social-network p {
	margin: auto;
	width: inherit;
}

.page.joinus .social-network a {
	display: inline-block;
	position: relative;
	background-size: contain;
	color: transparent;

	overflow: hidden;
	width: 2em;
	height: 2em;
	margin: 0 0.5em;
}

.page.joinus .social-network a.facebook {
	background-image: url(/images/facebook.png);
}

.page.joinus .social-network a.twitter {
	background-image: url(/images/twitter.png);
}

.page.joinus .social-network a.instagram {
	background-image: url(/images/instagram.png);
}

.page.joinus .social-network a.artstation {
	background-image: url(/images/artstation.png);
}